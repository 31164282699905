import styled from "@emotion/styled"

export const Content = styled.p`
  max-width: 46rem;
  margin-bottom: 2.75rem;
`

export const IngredientsHeading = styled.h4`
  margin-bottom: 1rem;
`
