import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image";
import { mediaQueries } from "../../styles"

export default styled(GatsbyImage)`
  ${mediaQueries.max576} {
    margin-left: -34px;
    margin-right: -34px;

    & > div {
      padding-bottom: 100% !important;
    }
  }
`
