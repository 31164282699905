import React from "react"
import styled from "@emotion/styled"

import { mediaQueries } from "../../styles"
import { Recipe } from "../../types/Recipe"

const List = styled.ul`
  list-style: none;
  margin-left: 0;

  ${mediaQueries.max576} {
    font-size: 12px;
  }
`

type Props = Pick<Recipe, "ingredients">


const IngredientsList: React.FC<Props> = ({ ingredients, ...props }) => {
  return (
    <List {...props}>
      {ingredients.map((i, index) => (
        <li key={index}>{i.content}</li>
      ))}
    </List>
  )
}

export default IngredientsList
