import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { mediaQueries } from "../../styles"
import { GatsbyImage } from "gatsby-plugin-image"

import type { Step as StepType } from "../../types/Recipe"

const Step: React.FC<StepType & { count: number }> = ({
  count,
  content,
  photo,
}) => {
  const { t } = useTranslation("", { keyPrefix: "recipeItem" })

  return (
    <StepWrapper>
      <OverlapGrid
        count={count}
        css={count % 2 === 0 && alternateOverlapGridStyles}
      >
        <Count>{count.toString().padStart(2, "0")}</Count>

        {photo && <GatsbyImage image={photo.gatsbyImageData} alt={content} />}
      </OverlapGrid>
      <Content>
        <h4>
          {t("step")} {count}
        </h4>
        <p>{content}</p>
      </Content>
    </StepWrapper>
  )
}

const StepWrapper = styled.div`
  display: flex;
  align-items: center;

  ${mediaQueries.max576} {
    flex-direction: column;
    margin-left: -34px;
    margin-right: -34px;
    padding-left: 34px;
    padding-right: 34px;
    width: 100vw;
  }
`

const Content = styled.div`
  max-width: 23rem;
  padding-right: 1rem;
  padding-left: 1rem;

  ${mediaQueries.max576} {
    h4 {
      margin-bottom: 0.5rem;
    }
  }
`

const OverlapGrid = styled.div<{ count: number }>`
  flex: 0 0 60%;
  flex: 0;
  display: grid;
  grid-template-rows: 125px 50px auto;
  grid-template-columns: minmax(0, 1fr);
  width: 100%;

  .gatsby-image-wrapper {
    grid-row: 2 / span 2;
  }

  ${mediaQueries.max576} {
    margin-bottom: 1rem;

    .gatsby-image-wrapper {
      display: block;
      max-width: 100vw;
      margin-left: -34px;
      margin-right: -34px;
    }
  }

  ${mediaQueries.min992} {
    order: ${props => (props.count % 2 === 0 ? 9999 : -1)};
    grid-template-columns: 120px 140px 220px 95px 24px;
    grid-template-rows: auto 2.75rem auto;

    .gatsby-image-wrapper {
      z-index: 1;
      grid-column: 2 / span 3;
      grid-row: 2 / span 2;
      width: 100%;
    }
  }
`

const alternateOverlapGridStyles = css`
  ${mediaQueries.min992} {
    grid-template-columns: 246px 160px 49px 76px;
    grid-template-rows: auto 4rem auto;

    span {
      justify-self: end;
      grid-column: 2 / span 3;
      grid-row: 1 / span 2;
    }

    .gatsby-image-wrapper {
      grid-column: 1 / span 3;
      grid-row: 2 / span 2;
    }
  }
`

const Count = styled.span`
  z-index: -1;
  position: relative;
  display: block;
  font-size: 180px;
  line-height: 1;
  color: var(--secondary);
  -webkit-text-stroke: 1px var(--secondary);
  -webkit-text-fill-color: transparent;

  ${mediaQueries.min992} {
    z-index: 0;
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
    font-size: 13vw;
  }
`

export default Step
