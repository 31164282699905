import styled from "@emotion/styled"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { mediaQueries } from "../../styles"
import { Recipe } from "../../types/Recipe"

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--secondary);

  ${mediaQueries.max576} {
    flex-direction: column;

    & > * {
      padding: 0;
    }
  }

  ${mediaQueries.min576} {
    & > *:first-of-type {
      padding-left: 0;
    }

    & > *:last-of-type {
      padding-right: 0;
    }
  }
`

const Detail = styled.div`
  color: var(--secondary);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.75rem;
  letter-spacing: 1.5px;
`

interface Props
  extends Pick<
    Recipe,
    "servings" | "cookingTime" | "prepTime" | "totalTime" | "author"
  > {
  className?: string
}

const Details = ({
  servings,
  cookingTime,
  prepTime,
  totalTime,
  author,
  ...props
}: Props) => {
  const { t } = useTranslation("", { keyPrefix: "recipeItem.details" })

  return (
    <DetailsContainer {...props}>
      <Detail>
        <strong>{t("servings")}:</strong>
        {servings || "4 porciones"}
      </Detail>
      <Detail>
        <strong>{t("cookingTime")}:</strong>
        {cookingTime}
      </Detail>
      <Detail>
        <strong>{t("prepTime")}:</strong>
        {prepTime}
      </Detail>
      <Detail>
        <strong>{t("totalTime")}:</strong>
        {totalTime}
      </Detail>
      <Detail>
        <strong>
          {t(author.gender === "male" ? "authorMale" : "authorFemale")}:
        </strong>
        {author.name}
      </Detail>
    </DetailsContainer>
  )
}

export default Details
